/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --background-color: #f9f9f9;
    --background-menu-color: #ffffff;
    /* --background-menu-color: #0a192f; */
    --text-color: #2d2e32;
    /* --text-color: #64ffda; */
    

}
/*
.dark-mode {*/
    /* --background-color: #181717;
    --background-menu-color: #333333;
    --text-color: #ffffff; */
   /* filter: invert(100%);*/
/*}*/