:root {
  --about-p-color: rgb(184, 239, 250);
  /*rgb(158, 240, 255)*/
}

html {
  scroll-behavior: smooth;
}

* {
  border: none;
  outline: none;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: rgb(255, 255, 255);
}

@media screen and (min-width: 1000px) {
  body {
    cursor: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home h1,
#home h2,
#home h3 {
  /* color: var(--text-color); */
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

#home h1 {
  font-size: 1.5rem;
}

#home h2 {
  font-weight: 700;
}

#home h3 {
  font-size: 1.2rem;
  font-weight: 600;
}


.padleft {
  padding-left: 4vw;
}

#about h1,
#about h2 {
  color: var(--text-color);
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

.title-trait {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  width: 50%;
  white-space: nowrap;
}

.title-trait::after {
  content: "";
  display: block;
  position: relative;
  width: 30vw;
  height: 1px;
  margin-left: 20px;
  background-color: #233554;
}

@media (max-width: 950px) {

  #home h1,
  #about h1,
  #home h2 {
    font-size: 2rem;
    font-weight: 700;
  }

}

@media (max-width: 600px) {

  #home h1,
  #about h1,
  #home h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
}


#home .home-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 4rem;
  margin-right: 5vw;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 10%;
}

@media (max-width:950px) {
  .home-content {
    width: 80%;
  }

  #home .home-content {
    margin-right: 0 !important;
  }

  .main-content {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 800px) {

  .main-content {
    max-width: 80%;
  }

  .home-content {
    margin-top: -20vh;
    flex-direction: column;
  }

  .home-conteneur {
    height: 80vh !important;
  }

  .list-technos>p {
    margin-right: 6vw !important;
  }

  .list-technos>ul {
    gap: 0;
    width: 40vw;
  }

  .list-technos>ul>li {
    gap: 5vw;
  }

}

@media (max-width: 780px) {
  /* .stacks ul {
    display: flex;
    flex-direction: column !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    gap: 0 !important;
  } */

  .stacks ul {
    /* display: grid; */
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 1rem;
  }

  #about li {
    height: 5rem !important;
    width: 5rem !important;
  }


  .stacks ul li {
    margin-bottom: 1rem;
  }

  #about li>img {
    max-height: 2.5rem;
    max-width: 2.5rem;
  }
}

@media (max-width: 580px) {
  #about li>img {
    max-height: 2.5rem;
    max-width: 2.5rem;
  }
}

#about li {
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .09);
  cursor: pointer;
  display: flex;
  height: 9rem;
  width: 9rem;
}

#about li::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: var(--text-color);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  bottom: -1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

#about li:hover::after {
  opacity: 1;
  visibility: visible;
}


#about li>img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}


.box-timeline {
  width: 50%;
  height: 410px;
  position: relative;
  margin-bottom: 100px;
  /* border-top: solid 1px rgb(255, 0, 0);
  border-bottom: solid 1px black; */
  margin-right: 5%;
  margin-left: auto;
}

.ligne {
  height: 610px;
  width: 4px;
  margin: 0 auto;
  background-color: #393838;
  position: relative;
}

.ligne::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 10px;
  border-top: 1px dashed black;
}

/* (flèche) */
.ligne::after {
  content: "";
  position: absolute;
  top: calc(100% - 6px);
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  top: calc(100% - 2px);
}

.rond {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(163, 225, 241);
  position: absolute;
  border: 1px solid #000;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}

.rond1 {
  top: -2px;
}

.rond3 {
  top: 205px;
}

.rond4{
  top: 380px;
}

.box {
  color: black;
  width: 400px;
  min-height: 100px;
  padding: 20px;
  border-radius: 3px;
  height: auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  padding-right: 5px;
}

.b1 {
  top: 10px;
  left: calc(50% - 220px);
  border-top: solid 1px rgba(38, 186, 249, 0.4);
  border-right: solid 1px rgba(38, 186, 249, 0.4);
  box-shadow: -8px 8px 8px rgba(38, 186, 249, 0.4);
  border-radius: 30px;
  border-top-right-radius: 0px;
}

.b2 {
  top: 215px;
  left: calc(50% + 220px);
  border-top: solid 1px rgba(38, 186, 249, 0.4);
  border-left: solid 1px rgba(38, 186, 249, 0.4);
  box-shadow: 8px 8px 8px 0px rgba(38, 186, 249, 0.4);
  border-radius: 30px;
  border-top-left-radius: 0px;
}

.b3 {
  top: -215px;
  left: calc(50% - 420px);
  border-top: solid 1px rgba(38, 186, 249, 0.4);
  border-right: solid 1px rgba(38, 186, 249, 0.4);
  box-shadow: -8px 8px 8px rgba(38, 186, 249, 0.4);
  border-radius: 30px;
  border-top-right-radius: 0px;
}

.box p {
  line-height: 20px;
  font-size: 18px;
  margin: 5px 0 10px 0;
}

.about-p p {
  margin-top: 3vh;
  text-align: justify;
  font-size: 16px;
}

.about-p {
  background-color: var(--about-p-color);
  position: relative;
  border-radius: 40px;
}

.about-p:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 50px;
  right: auto;
  top: auto;
  bottom: -30px;
  border: 20px solid;
  border-color: var(--about-p-color) transparent transparent var(--about-p-color);
}

/* .about-p::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  animation: border-animation 3s linear infinite;
}

@keyframes border-animation {
  0% {
    border-color: transparent;
    box-shadow: none;
  }
  50% {
    border-color: #00b3ff;
    box-shadow: 0 0 10px #73c9ff;
  }
  100% {
    border-color: transparent;
    box-shadow: none;
  }
} */


@media screen and (max-width: 1000px) {

  .ligne {
    width: 4px;
  }

  .box {
    padding: 10px 4px 10px 10px !important;
  }

  .box h2 {
    margin-bottom: 0.5rem !important;
  }

  .box p {
    width: 100%;
    font-size: 14px;
    margin: 2px 0 5px 0;
  }

  .b1 {
    top: 10px;
    left: 25%;
    width: 40%;
  }

  .b2 {
    top: 220px;
    left: calc(50% + 100px);
    width: calc(100% - 255px);
  }

  .b3{
    left: 25px;
    width: calc(100% - 255px);
  }

  .box-timeline {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-top: 20px;
  }

  .about-p-t {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  .about-p {
    width: 90% !important;
    margin: 5% !important;
  }

  .about-p p {
    font-size: 16px;
  }

}

.reveal {
  position: relative;
  opacity: 0;
  transition: all 1s ease;
  transform: translateY(250px);
  pointer-events: none;
}

.reveal.active {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}

/* translate gauche */
.revealx2 {
  position: relative;
  opacity: 0;
  transition: all 1s ease;
  transform: translateX(250px);
  pointer-events: none;
}

/* translate droite */
.revealx1 {
  position: relative;
  opacity: 0;
  transition: all 1s ease;
  transform: translateX(-250px);
  pointer-events: none;
}

.revealx1.active,
.revealx2.active {
  opacity: 1;
  transform: translateX(0px);
  pointer-events: auto;
}

.proj {
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 1.5rem;
}

.iframe {
  border-top-left-radius: 1.5rem;
  transform: translateY(0%);
  transition: transform 10s ease-in-out 0s;
}

.link:hover {
  color: #1b81f5;
  transition: all .3s;
}

@media screen and (max-width: 1000px) {
  .proj {
    width: 90% !important;
    display: block !important;
    padding: 5% !important;
  }

  .iframe {
    width: 80vw !important;
    margin: auto !important;
    height: 30vh !important;
  }

  .droite h2 {
    margin-top: 2vh !important;
  }


}

.cursor {
  overflow-x: visible;
  background: #ffd271;
  padding: 1.5rem;
  position: absolute;
  border-radius: 50%;
  -webkit-transition-duration: 10ms;
  transition-duration: 10ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-animation: cursorAnimation .5s infinite alternate;
  animation: cursorAnimation .5s infinite alternate;
  pointer-events: none;
  z-index: 1000;
}

.cursor::after {
  content: "";
  padding: 2rem;
  border: 2px solid #ffd271;
  position: absolute;
  border-radius: 50%;
  opacity: .5;
  top: -10px;
  left: -10px;
}


@-webkit-keyframes cursorAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@keyframes cursorAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@-webkit-keyframes cursorAnimation2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}

@keyframes cursorAnimation2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}

.red {
  background: #b82424be;
}

/* contact */

#contact {
  width: 80%;
  margin: 0 auto;
}

#contact #contact-block {
  padding: 0.5rem;
  margin-left: 3rem;
  margin-top: 2rem;
  text-align: right;
}

#contact #contact-block .contact-input {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#contact #contact-block label {
  font-size: 1rem;
  width: 15%;
  margin-right: 1rem;
}

#contact #contact-block button {
  padding: 0.6rem 2rem;
  margin-right: 0;
  border: black 1px solid;
  margin-right: 10%;
}

#contact #contact-block button:hover {
  background-color: black;
  color: white;
}

#contact input,
#contact textarea {
  width: 80%;
  padding: 0.6rem;
  margin-right: 10%;
  border: 1px solid #000;
}

@media screen and (max-width: 1000px) {
  #contact #contact-block {
    margin-left: 0;
    text-align: left;
  }

  #contact #contact-block .contact-input {
    flex-direction: column;
    gap: 1rem;
  }

  #contact #contact-block label {
    width: 100%;
    margin-right: 0;
  }

  #contact #contact-block input,
  #contact #contact-block textarea {
    width: 100%;
    margin-right: 0;
  }
}


/* skills block */

.skill-block {
  text-align: left;
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  display: inline-block;
}

.skill-block-heading {
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.skill-line {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 15px;
  border: 1px solid #000;

}

.animation {
  height: 12px;
  background-color: black;
  color: white;
  line-height: 12px;
  text-align: center;
  transition: width 2s ease;
}

.line-progress {
  position: relative;
}

.skill-block span {
  display: block;
  margin-left: auto;
}

@keyframes progressAnimation {
  to {
    width: 100%;
  }
}

.skill-name-img {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}

.skill-name-img span {
  margin-left: 0px !important;
  margin-right: 6px;
}

.skill-name-img img {
  margin-bottom: 5px;
  margin-left: 0 !important;
}

@keyframes progressAnimation {
  from {
    width: 0;
  }

  to {
    width: var(--skillPercentage);
  }
}

.animation.active {
  animation: progressAnimation 1.5s ease;
}

.front-back {
  margin-left: 10vw;
  position: relative;
  display: flex;
  -moz-box-align: center;
  width: 100%;
  white-space: nowrap;
}

.front-back::after {
  margin-top: 1rem;
  content: "";
  display: block;
  position: relative;
  width: 30vw;
  height: 1px;
  margin-left: 20px;
  background-color: #233554;
}


@media screen and (max-width: 800px) {
  .skill-block {
    width: 47% !important;
  }

  .skill-line>span {
    margin-left: 2px;
  }

}

@media screen and (max-width : 1000px) {
  .padleft {
    padding-left: 0 !important;
  }
}